<template>
  <div>

             <div class="contenttow content">
              <h5>{{$t('content.Miner')}}</h5>
              <span></span>
               <div class="con conlist">
                <div class="conlist" v-for="item in Miner_List">
               <h2>{{item.title}}</h2>
               <ul>
                <li>{{$t('content.period')}}:{{item.cycle}}{{$t('content.day')}}</li>
                <li>{{$t('content.output')}}:AOC</li>
                <li>{{$t('content.computing')}}:{{item.suanl}}GH/s </li>
                <li>{{$t('content.earnings')}}：{{item.income}}AOC</li>
               </ul>
       
                <p class="h3">≈{{item.shouyi}}</p>
                <p>{{item.pricecoin.toUpperCase()}}</p>
                <a class="shopping-button" :href="h5" >{{$t('navbar.shopping')}}</a>
                </div>
          
         

              </div>

             </div>
             <div class="contentse content">
                 <h5>{{$t('content.Howplay')}}</h5>
                <span></span>
                <div class="introduce">
                  <div class="introduce-bady">
                  <div class="introduce-box" v-for="item in list" :key="item.id" @click="changstep(item.id,item.imgs)">
                    <h2 :class="item.id === hig?'heightcolor':''">{{item.id}}</h2>
                    <div class="home-text">
                     <h4 >{{$t(item.tit)}}</h4>
                     <p>{{$t(item.text)}}</p>
                    </div>
                    </div>
                    <a class="introduce-button" :href="h5" >{{$t('navbar.start')}}</a>
                  </div>
                  <img :src="'imgs/'+imgUrl">

                </div>
               <div class="introduce-mo">
                 <div class="introduce-mo-nav">
                   <h3 v-for="item in list" :class="item.id == hig?'heibag': ''" @click="changstep(item.id,item.imgs)" :key="item.tit">{{$t(item.tit)}}</h3>
                 </div>
                 <div class="introduce-mo-centent">
                   <img :src="'imgs/'+imgUrl">
                   <div class="introduce-mo-bottom">
                     <p>{{$t('navbar.hint')}}</p>

                   </div>

                 </div>

               </div>
                  <div class="content04 content">
                               <h5>{{$t('content.Partner')}}</h5>
                                <span></span>
                                  <div class="conZ">
                                    <div v-for="item in parlist">
                                      <a :href="item.link">
                                       <img :src="item.img">
                                       <p>{{item.title}}</p>
                                      </a>
                                  </div>  
                                    </div>
                                 
                  </div>
    
             </div>
  </div>
</template>

<script>

import {getMiner_record,getConfig,getPartner} from "@/utils/request";

export default {
  data(){
    return {
      list:[
        {
          tit:"content.buymining",
          text:"content.text01",
          id:"01",
          imgs:"introduce.png"

        },
        {
          tit:"content.Lockmining",
          text:"content.text02",
          id:"02",
          imgs:"item-box02.png"

        },  {
          tit:"content.Teambenefits",
          text:"content.text03",
          id:"03",
          imgs:"item-box3.png"

        }
      ],
      Miner_List:[],//矿机列表
      nav:[
        {
          id:"01",
          tet:"购买矿机"
        },  {
          id:"02",
          tet:"锁仓挖井"
        },  {
          id:"03",
          tet:"团队收益"
        }
      ],
      hig:"01",
      imgUrl:"introduce.png",
      output:"",
      user:JSON.parse(localStorage.getItem("user")),
      h5:"",
      lang_type: [
        { type: "ch", name: "中文" },
        { type: "en", name: "English" },
        { type: "my_ZG", name: "Tiếng Việt" },
        { type: "ja_JP", name: "日本語" },
      ],
      parlist:[]

    }
  },
  created() {
    if(this.user.h5_http==1){
          this.h5 = "https://"+this.user.h5_link
        }else {
          this.h5 = "http://"+this.user.h5_link
        }
    getConfig().then(res=>{
          this.output = res.data.output
      this.getres()
    })
    this.getPar()
    let user = JSON.parse(localStorage.getItem("user"))
    this.$store.commit('setBan',user.url+"Upload/public/"+user.wapsildea)

  },
  methods:{
    changstep(id,imgs){
      this.hig = id ;
      this.imgUrl = imgs

},getres(){
      getMiner_record({id:0})
          .then(res=>{
            if(res.code==1){
              this.Miner_List = res.data
              this.Miner_List.map(item=>{
              item.shouyi=this.user.to_day_price*item.income
              })
            }
          })
},
async getPar(){
 
      let data={
        page:1,
        language:"中文"
      }
      localStorage.getItem("lang")
      this.lang_type.map(item=>{
        if( localStorage.getItem("lang")==item.type){
          data.language = item.name
        }
      })
      if(data.language == ""){
        data.language="中文"
      }
      let res = await getPartner(data)
      if(res.code == 1){

        res.data.map(item=>{
          item.img=this.user.url+item.img
          if(item.status=="1"){
            this.parlist.push(item)
          }
        }) 
     
      }
    }
}
}
</script>

<style>

</style>
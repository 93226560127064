module.exports={
    navbar:{
        text:"English",
        homepage:"home page",
        Digitalprice:'Digital currency price',
        Helpcenter:"Help center",
        Aboutus:"About us",
        contactus:"contact us",
        shopping:"立即购买",
        Intelligent:'Intelligent Robot Management',
        mining:'Start cloud mining',
        start:"start using",
        go:"let's get started",
        Fastertit:"Faster and more secure Bitcoin cloud mining computing power platform",
        Fasterp:'HashShiny.io aims to expand its business to the entire cryptocurrency industry chain and serve global users through technological innovation',
        hint:"Recharge the encrypted account to recharge, you can start currency transactions, you can choose a variety of payment methods to recharge",
        jump:"Click to jump",
        Serve:"Terms Policy",
        details:"查看详情",

    },
    content:{
        Miner:"Miner Mall",
        Howplay:"How to play",
        Partner:"Partner",
        Company:"Company Profile",
        download:"Download link",
        founder:"founder",
        now:"Buy now",
        day:"day",
        introduction:"team introduction",
        period:'lease period',
        output:"output currency",
        computing:"computing power",
        earnings:"Expected earnings",
        buymining:" buymining",
        Lockmining:"Lock-up mining",
        Teambenefits:"Team benefits",
        text01:"Recharge the encrypted account to recharge, you can open currency transactions, you can choose a variety of payment methods to recharge",
        text02:"Complete identity verification to ensure personal account security and transactions",
        text03:"Get everything ready, buy and sell cryptocurrencies, set up scheduled votes, and explore Binance products and services",
        founder:"founder",
        handsome:"handsome guy",
        introduce:"Graduated from Pushkin State University in Brest, he served as a special lecturer at the Beijing Municipal University Teacher Training Center, and served as a teacher training in Beijing universities for many years. Author of \"HTML5 from entry to proficient\", \"Jquery from entry to actual combat\", \"PhotoShop CC practical introduction\", \"Unity virtual reality development practice\", \"Data Structure and Algorithm (C Language)\", \"Nginx High Performance Web\" Server Application and Actual Combat (Micro-Lesson Edition)\" and many other books"
    },
 foo:{
     Terms:"Terms and Policies",
     Choose:"Choose a language",
     Service:"Terms of Service",
     Privacy:"Privacy Policy",
     Mail:"Mail",
     Fast:"Fast navigation"
 },
    lang:{
        Chinese:"Chinese",
        English:"English",
        Burmese:"Burmese"
    },
    Currency:{
        Frequently:"Frequently Asked Questions"
    },
    Digital:{
        currencyprices:"Popular digital currency prices",
        Highest:"Highest",
        lowest:"lowest",
        volume:"volume",
        Quote:"Quote change",
        name:"name",
        Hvolume:"24H volume",
        price:"price",}




}
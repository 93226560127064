<template>
  <div >
    <div class="fooder">
        <div class="fooder-cen">
          <div class="fooder-box">
            <h1>{{$t('navbar.contactus')}}</h1>
            <b class="before"></b>
            <ul>
              <li>{{$t('foo.Mail')}}:</li>
              <li>{{gfemail}}</li>
              <li>{{$t('navbar.contactus')}}:</li>
              <li  v-for="item in linklist" :key="item.img">
                <a :href="item.link" target="_blank" class="linkloge-item">
                <img :src ="item.img" class="linkloge">
                <span>{{item.title}}</span>
              </a>
              </li>
            </ul>
          </div>
                <div class="fooder-box">
            <h1>{{$t('foo.Fast')}}</h1>
            <b class="before"></b>
            <ul>
              <li v-for="item in nav"  @click="setban($event,item.bag,item.path)" :key="item.name"> <a  >{{$t(item.name)}}</a></li>
            </ul>

          </div>
                <div class="fooder-box">
            <h1>{{$t('foo.Terms')}}</h1>
            <b class="before"></b>
            <ul>
           
              <li><p v-if="privactlink==''?true:false" @click="goServe" >{{$t('foo.Service')}}</p><a :href="privactlink" v-if="privactlink ==''?false:true" >{{$t('foo.Service')}}</a></li>
              <li><p  v-if="Servicelink==''?true:false"   @click="goPrivacy">{{$t('foo.Privacy')}}</p><a :href="Servicelink" v-if="Servicelink==''?false:true" >{{$t('foo.Privacy')}}</a></li>
            </ul>

          </div>
                <!-- <div class="fooder-box">
            <h1>{{$t('foo.Choose')}}<i>Choose a language</i></h1>
            <b class="before"></b>
            <ul>
              <li v-for="item in lang_type" @click="Language(item.name,item.type)" :key="item.name">{{item.name}}</li>

            </ul>
          </div> -->
        </div>

    </div>

  <!-- <h6>晋ICP备2022004275<i class="span">号</i></h6> -->
  </div>
</template>

<script>
import {getConfig} from "../utils/request";
import {getPopup,getServe} from "../utils/request"
export default {
  props:["nav"],
  name:"foo",
  data(){
    return{
      lang_type: [
        { type: "ch", name: "中文" },
        { type: "en", name: "English" },
        { type: "my_ZG", name: "Tiếng Việt" },
      ]
      ,heiclo:""
      ,gfemail:"",
      linklist:"",
      privactlink:"",
      Servicelink:"",

    }
  },
  created() {
    this.gfemail = localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).gfemail:""
    this.getpop_updata()
    this.getser()
    this.getper()
    // this.gfemail =  JSON.parse(localStorage.getItem("user")).gfemail
  },
  methods:{
    Language(lang,type) {
      this.langShow = lang
      localStorage.setItem("lang", type)
      localStorage.setItem("show", lang)
      this.$i18n.locale = type;
      this.$router.go()
    },
    async getpop_updata(){
   let res = await getPopup({page:0})
   if(res.code == 1){
    res.data.map(item=>{
      let ht = "http://"
      item.img = JSON.parse(localStorage.getItem("user")).url+item.logo
    })
     this.linklist = res.data
   }
   },
    goBack() {
      document.documentElement.scrollTop = 0;
    },
   goServe(){
     
      this.$router.push({ path: "/Serve" })
      this.goBack()
    },
    goPrivacy(){
      this.$router.push({ path: "/Privacy" })
      this.goBack()
    },
    async getser(){
      let data={
        type:"2", language:""
      }
      let lang_type=[
          { type: "ch", name: "中文" },
          { type: "en", name: "英文" },
          { type: "my_ZG", name: "越南语" },
          { type: "ja_JP", name: "日本" },
        ]
      lang_type.map(item=>{
        if( localStorage.getItem("lang")==item.type){
          data.language = item.name
        }
      })
      if(data.language == ""){
        data.language="中文"
      }

      let res = await getServe(data)
      let user = JSON.parse(localStorage.getItem("user"))
      if(res.code==1){
 
       this.privactlink = res.data.link
      }
  
    },
    async getper(){
      let data={
        type:"1", language:""
      }
      let lang_type=[
          { type: "ch", name: "中文" },
          { type: "en", name: "英文" },
          { type: "my_ZG", name: "越南语" },
          { type: "ja_JP", name: "日本" },
        ]
      lang_type.map(item=>{
        if( localStorage.getItem("lang")==item.type){
          data.language = item.name
        }
      })
      if(data.language == ""){
        data.language="中文"
      }
      let res = await getServe(data)
      let user = JSON.parse(localStorage.getItem("user"))
      if(res.code==1){
       this.Servicelink = res.data.link
      }
  
    },
    destroyed() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
    setban(e,bag,path) {
      this.goBack()
      if(path){
        this.$store.commit('setBan',bag)
        this.$router.push({ path: path })
      }else {
        this.$store.commit('showMSG')
        this.$router.push({ path: this.$route.path })
      }
      if(path== "/"){
        sessionStorage.setItem("sh", JSON.stringify(0))
        // this.isShow = true
        this.$store.commit('setShow',true)
      }else if(path){
        // this.isShow = false
        sessionStorage.setItem("sh", JSON.stringify(1))
        this.$store.commit('setShow',false)
      }
      localStorage.setItem("bag",bag);
    },

  },
  mounted() {
    // 侦听事件并处理相应函数
    window.addEventListener("scroll", this.scrollToTop);
  },
}
</script>

<style>

</style>
<template>
  <div>
    <div class="lang-wrap text-center">
        <el-dropdown>
          <div class="lang-wrap-text">
            <h5 style="color: white">{{ langShow }}</h5>
            <i class="el-icon-arrow-down el-icon--right" style="color: white"></i>
          </div>
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item v-for="item in lang_type"  :key="item.name" style="color: #000000"  @click.native="Language(item.name,item.type)"> {{ item.name }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

    </div>
  </div>
</template>

<script>
import {arrow} from '../assets/js/animal'
export default {
  computed: {

    themeMG() {
      let theme = this.theme;
      if (theme == "light") {
        return "#fff";
      } else {
        return "#171823";
      }
    },
  },
  data() {
    return {
      is_lang: false,
	  langShow:"中文",
      lang_type: [
        { type: "ch", name: "中文" },
        { type: "en", name: "English" },
        { type: "my_ZG", name: "Tiếng Việt" },
        { type: "ja_JP", name: "日本語" },
      ]
    };
  },
created(){
// this.Language()
this.langShow = localStorage.getItem("show")?localStorage.getItem("show"):"中文"
},
  methods: {
    //localStorage.getItem("show")?localStorage.getItem("show"):"语言"
    // langChange(type) {
    //   this._i18n.locale = type;
    //   this.changeLang(type);
    // },
    Language(lang,type) {
		this.langShow = lang
		localStorage.setItem("lang", type)
		localStorage.setItem("show", lang)
		this.$i18n.locale = type;
    this.$router.go()
    },
    showarrw(e){
      arrow(e.target)
      this.arrowDom = e.target
      // e.target.style.transform =="rotate(180deg)"?
    }
  },
  watch:{
   is_lang: function(){
     arrow(this.$refs.bth)
   }
  }
};
</script>

<style scoped lang="scss">
.lang-wrap {
  position: relative;
  margin-left: 5em;
  cursor: default;
  & > .lang-content {
    position: absolute;
    & > p{
      padding: 0.5em 0.5em;
      width: max-content;
      text-align: center;
      cursor:default;
    }
  }
}
.lang-wrap-arrow{
 width: 0;
  display: inline-block;
  border: 8px solid transparent;
  border-top: 8px solid white;
  margin-top: 0.5em;
  margin-left: 0.8em;

}
.lang-wrap-text{
  display: flex;
  align-items: center;
  h5{
    font-size: 18px;
  }
}
</style>
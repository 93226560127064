<template>
  <div class="pop_up_body" v-if="$store.state.MSG">
      <div class="pop_up" >
        <h2>{{$t('navbar.jump')}}</h2>
        <div class="pop_up_item">
          <i class="el-icon-close" @click="setShow"></i>
        <div v-for="item in list">
          <h1>{{item.title}}</h1>
          <a :href="item.link" target="_blank" @click="setShow">
            <img :src =item.img>
          </a>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import {getPopup} from "../utils/request"
export default {
  name:"Pop-up",
  props:["ispop"],
  data() {
    return {
   list:[
   ],
      is_show:true
    };
  },
created() {
this.getpop_updata()
  this.is_show=this.$store.state.MSG
},
  methods: {
   async getpop_updata(){
   let res = await getPopup({page:0})
   if(res.code == 1){
    res.data.map(item=>{
      let ht = "http://"
      item.img = JSON.parse(localStorage.getItem("user")).url+item.logo
    })
     this.list = res.data
   }
   },
    setShow(){
      this.$store.commit('hideMSG')
    }
  },
  watch:{

  }
};
</script>

<style scoped lang="scss">

</style>
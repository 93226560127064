import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store/index'
import VueI18n from 'vue-i18n'
import {Service} from './utils/http.js'
import Vant from 'vant'
import {getConfig} from "../src/utils/request";
Vue.prototype.$axios=Service
Vue.use(ElementUI);
Vue.use(VueI18n)
Vue.use(Vant)
Vue.config.productionTip = false

const i18n = new VueI18n({
    locale: localStorage.getItem('lang')?localStorage.getItem('lang'):'ch',
    silentTranslationWarn: true,
    messages:{
        'ch':require('./i18n/lang/ch'), //中文语言包
        'en':require('./i18n/lang/en'), //英文语言包
        'my_ZG':require('./i18n/lang/my_ZG'), //缅甸语
    }
})
// main.js
router.beforeEach((to, from, next) => {
    // to去哪  from  来自
    if(to.meta.title) {
        document.title =document.title = `${i18n.t('navbar.'+to.meta.title)}`
    }
    if (to.meta.icon) {
        getConfig().then((res)=>{
            let user = res.data
            user.url = "https://admin.ertyoh.com/"
            localStorage.setItem("user", JSON.stringify(user))
             let url =user.url+"Upload/public/"+res.data.waplogo
            document.getElementsByTagName('link')[0].setAttribute('href', url)
        })


    }


    next()
})
Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app')
module.exports={
    navbar:{
        text:"越南语",
        homepage:"trang đầu",
        Digitalprice:'giá tiền kỹ thuật số',
        Helpcenter:"Trung tâm trợ giúp",
        Aboutus:"về chúng tôi",
        contactus:"liên hệ chúng tôi",
        Intelligent:'Quản lý Robot thông minh',
        mining:'Bắt đầu khai thác trên đám mây',
        start:"bắt đầu sử dụng",
        shopping:"立即购买",
        go:"Bắt đầu nào",
        Fastertit:"Nền tảng điện toán khai thác Bitcoin trên đám mây nhanh hơn và an toàn hơn!",
        Fasterp:'HashShiny.io đặt mục tiêu mở rộng hoạt động kinh doanh của mình sang toàn bộ chuỗi ngành công nghiệp tiền điện tử và phục vụ người dùng toàn cầu thông qua đổi mới công nghệ',
        hint:"Nạp tiền vào tài khoản mã hóa để nạp tiền, bạn có thể bắt đầu giao dịch tiền tệ, có thể chọn nhiều phương thức thanh toán để nạp tiền",
        jump:"Bấm để nhảy",
        Serve:"Chính sách Điều khoản",
        details:"查看详情",


    },
    content:{
        Miner:"Miner Mall",
        day:"天",
        Howplay:"Cách chơi",
        Partner:"Bạn đồng hành",
        Company:"Hồ sơ công ty",
        download:"Liên kết tải xuống",
        founder:"người sáng lập",
        period:'Thời gian thuê',
        output:"tiền tệ đầu ra",
        computing:"khả năng tính toán",
        earnings:"Nguồn thu nhập được dự kiến",
        now:"Mua ngay",
        introduction:"giới thiệu độ",
        buymining:"buymining",
        Lockmining:"Khai thác khóa",
        Teambenefits:"Lợi ích của nhóm",
        text01:"Nạp tiền vào tài khoản mã hóa để nạp tiền, có thể mở giao dịch tiền tệ, có thể chọn nhiều phương thức thanh toán để nạp tiền",
        text02:"Hoàn thành xác minh danh tính để đảm bảo an toàn tài khoản cá nhân và các giao dịch",
        text03:"Chuẩn bị sẵn sàng mọi thứ, mua và bán tiền điện tử, thiết lập các phiếu bầu theo lịch trình và khám phá các sản phẩm và dịch vụ của Binance",
        founder:"người sáng lập",
        handsome:"anh chàng đẹp trai",
        introduce:"Tốt nghiệp Đại học Bang Pushkin ở Brest, ông từng là giảng viên đặc biệt tại Trung tâm Đào tạo Giáo viên Đại học Thành phố Bắc Kinh, và từng là giáo viên đào tạo tại các trường đại học Bắc Kinh trong nhiều năm. Tác giả của \"HTML5 từ mục nhập đến thành thạo\", \"Truy vấn từ khi nhập cảnh đến thực chiến\", \"Giới thiệu thực tế PhotoShop CC\", \"Thực hành phát triển thực tế ảo Unity\", \"Cấu trúc dữ liệu và thuật toán (Ngôn ngữ C)\", \"Web hiệu suất cao Nginx \"Ứng dụng Máy chủ và Chiến đấu Thực tế (Phiên bản Bài học Vi mô)\" và nhiều sách khác"
    }
    ,
    foo:{
        Terms:"Điều khoản và Chính sách",
        Choose:"Chọn một ngôn ngữ",
        Service:"Điều khoản dịch vụ",
        Privacy:"Chính sách bảo mật",
        Mail:"Thư",
        Fast:"Điều hướng nhanh chóng"
    },
    lang:{
        Chinese:"người Trung Quốc",
        English:"Tiếng Anh",
        Burmese:"Tiếng Việt"
    },
    Currency:{
        Frequently:"Các câu hỏi thường gặp"
    },
    Digital:{
        currencyprices:"Giá tiền kỹ thuật số phổ biến",
        Highest:"Cao nhất",
        lowest:"thấp nhất",
        volume:"âm lượng",
        Quote:"Báo giá thay đổi",
        name:"Tên",
        Hvolume:"24H khối lượng",
        price:"giá bán",
    }

}
// 箭头翻转的封装
let deg = 0
export const arrow = function(dom) {
    deg = deg == 0 ? 180 : 0;
    deg == 0 ? dom.style.marginTop =`0.5em`: dom.style.marginTop =`-0.5em`
    dom.style.transform = `rotate(${deg}deg)`

}

//下拉框的动画
let h = 0
export const dropDownUp = function(dom, height, cb) {
    if (h == 0) {
        let myTimer = setInterval(() => {
            h += 10
            if (h > height) {
                clearInterval(myTimer)
                h = height
                cb();
            }
            dom.style.height = h + 'px'
        }, 10)
    } else {
        cb();
        let myTimer = setInterval(() => {
            h -= 10
            if (h < 0) {
                clearInterval(myTimer)
                h = 0
            }
            dom.style.height = h + 'px'
        }, 10)
    }
}




export default {
    arrow,
    dropDownUp
}
import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        MSG: false,
        ban:"",
        isShow:false,
    },
    mutations: {
        showMSG(state){
            state.MSG = true

        },

        hideMSG (state) {
            state.MSG = false
        },
        setBan(state,payload){
            state.ban = payload
        },
        setShow(state,payload){
            state.isShow = payload
        }
    }
})
export default store

import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};

export default new Router({
    routes: [
        {
                path: '/Homepc',
                component: resolve => require(['../views/Homepc'], resolve),
            meta: {
                title: 'homepage',
                icon:true,
            }

            },
            {
                path: '/Foo',
                component: resolve => require(['../components/Foo.vue'], resolve)

            }, {
                path: '/',
                component: resolve => require(['../components/Content.vue'], resolve),
                meta: {
                requiresAuth: true, title: 'homepage',
                    icon:true,
                }
            },{
                path: '/Currency',
                component: resolve => require(['../views/Currency.vue'], resolve),
            meta: {
                title: 'Helpcenter',
                icon:true,
            }
            },
            ,{
                path: '/Company',
                component: resolve => require(['../components/Company.vue'], resolve),
            meta: {
                title: 'Aboutus',
                icon:true,
            }
            },{
             path: '/Digital',
              component: resolve => require(['../views/Digital.vue'], resolve),
            meta: {
                title: 'Digitalprice',
                icon:true,
            }
        },
        {
            path: '/Serve',
            component: resolve => require(['../components/Serve.vue'], resolve),
            meta: {
                title: 'Serve',
                icon:true,
            }
        },
        {
            path: '/Privacy',
            component: resolve => require(['../components/Privacy.vue'], resolve),
            meta: {
                title: 'Serve',
                icon:true,
            }
        }
            
    ]

})


module.exports={
	navbar:{
		text:"中文",
		homepage:"首页",
		Digitalprice:'数字货币价格',
		Helpcenter:"帮助中心",
		Aboutus:"关于我们",
		contactus:"联系我们",
		Intelligent:'智能机器人管理',
		mining:'开始云挖矿',
		start:"开始使用",
		shopping:"立即购买",
		go:"让我们一起开始",
		jump:"请点击跳转",
		Fastertit:"更快，更安全的比特币云挖矿算力平台!",
		Fasterp:'HashShiny.io旨在将业务扩展到整个加密货币产业链并通过技术创新为全球用户服务',
		hint:"充值加密账户充值，即可开启货币交易，您可选取多种支付方 式进行充值",
		Serve:"条款政策",
		details:"查看详情",



	},
	content:{
		Miner:"矿机商城",
		Howplay:"玩法介绍",
		Partner:"合作伙伴",
		Company:"公司介绍",
		download:"下载地址",
		introduction:"团队介绍",
		founder:"创始人",
		period:'租赁周期',
		output:"产出币种",
		computing:"算力",
		day:"天",
		earnings:"预计收益",
		now:"立即购买",
		buymining:"租用矿机",
		Lockmining:"锁仓挖矿",
		Teambenefits:"团队收益",
		text01:"一键式购买，次日发放当天收益",
		text02:"支持活期，定期锁仓。灵活管理资产。",
		text03:"多重邀请机制，动动手指即可获得丰厚奖励。",
		founder:"创始人",
		handsome:"帅哥",
		introduce:"毕业于布列斯特普希金国立大学，曾任北京市属高校师资培训中心特邀讲师，多年担任北京市高校师资培训工作。著有《HTML5从入门到精通》、《Jquery从入门到实战》、《PhotoShop CC实战入门》、《Unity虚拟现实开发实践》、《数据结构与算法（C语言篇）》、《Nginx 高性能 Web 服务器应用与实战（微课版）》等多本书籍"
	}
	,
	foo:{
		Terms:"条款和政策",
		Choose:"选择语言",
	    Service:"服务条款",
		Privacy:"隐私政策",
		Mail:"邮箱",
		Fast:"快速导航"
	},
	lang:{
		Chinese:"中文",
		English:"英语",
		Burmese:"缅甸语"
	},
	Currency:{
		Frequently:"常见问题解答"
	},
	Digital:{
		currencyprices:"热门数字货币价格",
		Highest:"最高",
		lowest:"最低",
		volume:"成交量",
		Quote:"涨跌幅",
		name:"名称",
		Hvolume:"24H量",
		price:"价格"
	}

}
<template>
  <div id="app">
        <Homepc></Homepc>
  </div>
</template>
<script>
import Homepc from './views/Homepc.vue';
export default {
    name: "App",
    components: { Homepc },
  data(){
     return {
       user:{},
     }
    },
}
</script>

<style lang="scss">

</style>


<template>
  <div>
    <PopUp :ispop="isshow"></PopUp>
    <div class="banner">
      <img :src="$store.state.ban" />
      <div class="banner-textbox" v-if="$store.state.isShow">
<!--        {{$t('navbar.mining')}}-->
        <h1>{{user.webname}}</h1>
        <p >{{$t('navbar.Fastertit')}}</p>
        <p class="small-size">{{$t('navbar.Fasterp')}}</p>
        <a class="banner-button" :href="h5" style="margin-top:3%;display:block">{{$t('navbar.go')}}</a>
       <span></span>
      <div class="banner-Download" v-if="$store.state.isShow">
        <div class="banner-Download-item">
          <a :href="ios" target="_blank">
          <img src="../assets/imgs/apple.png">
          </a>
          <div class="banner-Download-itemtext">
            <a :href="ios" target="_blank">
              <p>Download on the</p>
               <h5>App H5</h5>
            </a>
          </div>


        </div>
        <div class="banner-Download-item">
          <a :href="down" target="_blank">
          <img src="../assets/imgs/goodle.png">
          </a>
          <div class="banner-Download-itemtext">
            <a :href="down" target="_blank">
            <p>Git it on </p>
            <h5>GooGle play</h5>
            </a>
          </div>


        </div>

        <div class="banner-Download-item">
          <a :href="h5" target="_blank">
          <img src="../assets/imgs/apk.png">
          </a>
          <div class="banner-Download-itemtext">
            <a :href="h5" target="_blank">
            <p>Download</p>
            <h5>Android APK</h5>
            </a>
          </div>


        </div>


      </div>
      </div>
      <div class="twotext-box" v-if="!$store.state.isShow">
        <h1 class="twotext">{{user.webtitle}}</h1>
        <h1 class="twotext">{{$t('navbar.Intelligent')}}</h1>
      </div>
    </div>
    <header>
      <div class="versionheart">
        <div class="left lbox">
          <img :src="heaimg" />
<!--          user.weplogo-->
          <div class="text">
            <h1>{{user.webname}}</h1>
            <p>{{user.webtitle}}</p>
          </div>
        </div>
        <div class="right">
          <ul>
            <li
              v-for="(item, index) in list"
              :key="index"
              @click="setban($event,item.bag,item.path)"
              class="heili"
            >
              <p  style="color: white" >{{$t(item.name)}}</p>
            </li>
          </ul>
          <Lang class="lang-box"></Lang>
        </div>
        <div class="menu-text">
          <Lang class="lang-box"></Lang>
          <el-dropdown trigger="click">
          <div class="el-dropdown-link">
                           <img src="../assets/imgs/meue.png">
                  </div>
            <el-dropdown-menu slot="dropdown" >
              <el-dropdown-item v-for="item in list" :key="item.name"   @click.native="setban($event,item.bag,item.path)" > <p  style="color: black">{{$t(item.name)}}</p></el-dropdown-item>
              <!-- <el-dropdown-item v-for="item in lang_type" :key="item.name" style="color: black"  @click.native="Language(item.name,item.type)"> {{ item.name }}</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
       

        </div>

      </div>

    </header>
    <router-view></router-view>
    <Foo :nav="list"></Foo>
  </div>
</template>

<script>
import "../assets/css/met.scss";
import Foo from "../components/Foo.vue";
import Content from "../components/Content.vue";
import Lang from "../components/lang.vue";
import {getConfig} from "../utils/request";
import PopUp from "../components/Popup";
import { MessageBox } from 'element-ui';
import {getServe,getCompany} from '../utils/request'
export default {
  name: "Homepc",
  data() {
    return {
      user:"",
      hie:"",
      list: [
        {
          name: 'navbar.homepage',
          path: "/",
          bag:""
          // bag:(JSON.parse(localStorage.getItem("user")).url+JSON.parse(localStorage.getItem("user")).wapsildea)
        },
        {
          name: 'navbar.Digitalprice',
          path: "/Digital",
          bag:""
          // bag:(JSON.parse(localStorage.getItem("user")).url+JSON.parse(localStorage.getItem("user")).wapsildeb)
        },
        {
          name:  'navbar.Helpcenter',
          path: "/Currency",
          bag: "",
        },
        {
         name:  'navbar.Aboutus',
          path: "/Company",
          bag:"",
        },
      
      ],
      lang_type: [
        { type: "ch", name: "中文" },
        { type: "en", name: "English" },
        { type: "my_ZG", name: "Tiếng Việt" },
        { type: "ja_JP", name: "日本" },
      ],
      isShow:sessionStorage.getItem("sh") == "1"?false:true,
      heaimg:"",
      ban:"",
      isshow:false,
      h5:"",
      down:"",
      ios:"",
      // ,
    };
  },
  components: { Foo, Content, Lang,PopUp },
  created() {
    this.getusers()
  },
  methods: {
    setban(e,bag,path) {
      if(path){
        this.ban = bag
        // this.$store.commit('setBan',bag);
        this.$router.push({ path: path })
      }else {
        this.$store.commit('showMSG')
        this.$router.push({ path: this.$route.path })
      }
      if(path== "/"){
        sessionStorage.setItem("sh", JSON.stringify(0))
        this.isShow = true
        this.$store.commit('setShow',true)
      }else if(path){
        this.isShow = false
        this.$store.commit('setShow',false)
        sessionStorage.setItem("sh", JSON.stringify(1))
      }
      localStorage.setItem("bag",bag);
    },
    Language(lang,type) {
      this.langShow = lang
      localStorage.setItem("lang", type)
      localStorage.setItem("show", lang)
      this.$i18n.locale = type;
      this.$router.go()
    },
    async getusers() {
      let data={
     type:"2", language:""
    }
    let lang_type=[
          { type: "ch", name: "中文" },
          { type: "en", name: "英文" },
          { type: "my_ZG", name: "越南语" },
          { type: "ja_JP", name: "日本" },
        ]
      lang_type.map(item=>{
      if( localStorage.getItem("lang")==item.type){
        data.language = item.name
      }
    })
      if(data.language == ""){
        data.language="中文"
      }
      let res = await getConfig()
      if (res.code == 1) {
        this.user = JSON.parse(localStorage.getItem("user"))
        localStorage.setItem("user", JSON.stringify(this.user))
        this.heaimg =this.user.url+"Upload/public/"+this.user.waplogo
         this.$set(this.list[0],"bag",this.user.url+"Upload/public/"+this.user.wapsildea)
        this.$set(this.list[1],"bag",this.user.url+"Upload/public/"+this.user.wapsildeb)
        this.$set(this.list[2],"bag",this.user.url+"Upload/public/"+this.user.wapsildec)
        // this.$set(this.list[3],"bag",this.user.url+"Upload/public/"+this.user.wapsilded)
        // console.log(this.$route.path,"当前路由")
        // let arr = await getServe(data)
        // if(arr.code==1){
        //   this.$set(this.list[4],"bag",this.user.url+arr.data.img)
        //    console.log(arr,"服务1")
        // }
        let add = await getCompany(data)
        if(add.code==1){
          this.$set(this.list[3],"bag",this.user.url+add.data.img)
        }
        if(this.$route.path =="/"){
          this.$store.commit('setShow',true)
        }else {
          this.$store.commit('setShow',false)
        }
        this.ios=this.user.ios_download_link
        if(this.user.download_http==1){
          this.down = "https://"+this.user.download_link
        }else {
          this.down = "http://"+this.user.download_link
        }
        if(this.user.h5_http==1){
          this.h5 = "https://"+this.user.h5_link
        }else {
          this.h5 = "http://"+this.user.h5_link
        }
        this.list.map(item=>{ 
          if(item.path ==this.$route.path ){
            let bag = item.bag
            this.$store.commit('setBan',bag);
            this.ban = item.bag
          }

        })
      }
    }
},
}
</script>

<style lang="scss" scoped>
</style>
export function getMiner_record(productId) {
    return reqloading({
        url: '/mobile/orepool/index/',
        data: { productId: productId },
        method: 'post',

    })
}
export function getConfig(productId) {
    return reqloading({
        url: '/home/index/getConfig/',
        data: { productId: productId },
        method: 'post',
    })
}
export function getForm() {
    return req({
        url: '/home/index/index/',
        method: 'post',

    })
}
export function getForming() {
    return reqloading({
        url: '/home/index/index/',
        method: 'post',

    })
}
export function getCompany(productId) {
    return reqloading({
        url:'/mobile/index/AboutUs',
        method: 'post',
        data:qs.stringify({...productId})

    })
}

export function getPartner(productId) {
    return reqloading({
        url: '/mobile/index/Partner',
        method: 'post',
        data:qs.stringify({...productId})

    })
}
export function getTeamIntroduction(productId) {
    return reqloading({
        url: '/mobile/index/TeamIntroduction',
        method: 'post',
        data:qs.stringify({...productId})

    })
}
export function getCurremcy(productId) {
    return reqloading({
        url: '/mobile/index/HelpCenter',
        method: 'post',
        data:qs.stringify({...productId})

    })
}
export function getPopup(productId) {
    return reqloading({
        url: '/mobile/index/CustomerService',
        method: 'post',
        data:qs.stringify({...productId})

    })
}
export function getServe(productId) {
    return reqloading({
        url: '/mobile/index/otherInformation',
        method: 'post',
        data:qs.stringify({...productId})

    })
}
import {req,reqloading} from './axiso'
import  qs from 'qs'